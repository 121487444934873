.project-filter nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 8px 0px 0 0px !important;
    margin-bottom: 6px;
}

.project-filter nav button {
    position: relative;
    width: 100%;
    height: 35px;
    transition: 0.5s;
    background: #fff;
    border: none;
    border-bottom: 2px solid transparent;
}

.project-filter nav button:nth-child(1) {
    border-radius: 8px 0 0 8px;
}

.project-filter nav button:nth-last-child(1) {
    border-radius: 0 8px 8px 0;
}

.project-filter nav button span {
    font-size: 0.6rem;
    font-weight: normal;
    text-transform: capitalize;
}

.project-filter nav button.active {
    border-bottom: 2px solid var(--p-theme-purple);
    background: var(--p-theme-purple-bg);
}

.project-filter nav button.active span {
    font-size: 0.7rem;
    font-weight: bold;
    position: relative;
    top: 2px;
}

.project-filter nav button small {
    position: absolute;
    top: -8px;
    right: 20px;
    width: 22px;
    padding: 0px;
    height: 22px;
    font-size: 11px;
    background: var(--p-theme-purple);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    border-radius: 50%;
    line-height: 0px;
}