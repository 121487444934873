@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* variables */
:root {
  /* sanskar color var  */
  --theme-green: #20b2aa;
  --theme-light-green: #4CD7D0;
  --theme-orange: #FA6304;
  --white-color: #fff;
  --black-color: #000;
  --theme-blue: #3F5E98;
  --aflbg: #efefef;

  /* sanskar color var  */



  --heading-color: #3F5E98;
  --text-color: #444;

  --darkgrey-color: #666;
  --lightgrey-color: #aaa;
  --red-color: #E4021B;
  --peach-color: #FFBF90;

  --green-color: 188, 236, 224;
  --yellow-color: #FEEBA0;
  --cream-color: #F4F2EB;
  --bg-color: #F4F2EB;
  --buttonfg-color: #F4F2EB;

  --light-green: #8af5fc;
}

/* base styles */
body {
  font-family: 'Comfortaa', cursive;
  margin: 0;
  font-size: 0.9rem;
  background: var(--bg-color);
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  padding: 0 !important;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}

/* layout */
.page-title {
  /* font-size: 1em;
  color: var(--heading-color);
  display: inline-block; */
  font-size: 1.4rem;
  font-weight: bolder;
  color: var(--theme-blue);
  padding: 4px 0px;
  z-index: 10;
  display: flex;
  align-items: center;
}

.page-title h1 {
  font-size: 1.6rem;
  font-weight: bolder;
  padding-left: 8px;
}

.auth-form {
  width: 100%;
  margin: 30px auto 0px auto;
  padding: 20px;
  border: 1px solid #cfcfcf;
  border-radius: 15px;
}

.btn {
  /* background: var(--white-color); */
  background-color: var(--theme-orange);
  /* color: var(--red-color); */
  color: var(--buttonfg-color);
  padding: 8px 18px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bolder;
  border: 2px solid;
  border-color: var(--theme-orange);
}

.btn.cancel {
  background: var(--yellow-color);
  border-color: var(--yellow-color);
  color: var(--black-color);
}

.btn.info {
  background: var(--theme-blue);
  border-color: var(--theme-blue);
  color: var(--white-color);
}

.btn:hover {
  /* color: var(--bg-color); */
  color: var(--theme-orange);
  /* background-color: var(--red-color); */
  background: var(--buttonfg-color);
}

.section_title h3 {
  font-size: 26px;
  color: var(--theme-orange);
  text-transform: capitalize;
  margin-top: -10px !important;
}

.btn.cancel:hover {
  background: var(--cream-color);
  color: var(--theme-blue);
}

.section_title {
  position: relative;
}

.btn.info:hover {
  color: var(--theme-blue);
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.mybutton {
  background: var(--cream-color);
  outline: none;
  border: none;
  color: var(--white-color);
  padding: 7px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  /* border-radius: 8px; */
}

.button5 {
  background: linear-gradient(to right, #DC948B, #E4947C);
  /* background: #85cb0b; */
  background: var(--red-color);
  color: var(--cream-color);
  text-decoration: none;
  border-radius: 40px;
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0;
  transition: 0.5s;
}

.button5:hover {
  text-decoration: none;
  color: var(--cream-color);
}

.button_transparent {
  padding: 7px 15px 3px 15px;
  background: #fff;
  border: 1px solid var(--p-theme-purple);
  color: var(--p-theme-purple);
  border-radius: 8px;
}

/* pop up div - start */

.install-popup-div {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  height: 0;
  transition: 1.5s ease-in-out;
}

.install-popup-div.open {
  opacity: 1;
  pointer-events: all;
  height: 100vh;
  transform: translateY(0%);
  animation: popupInTransition 2s ease-in-out;
}

@keyframes popupInTransition {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.install-popup-div div {
  position: relative;
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 12px;
  padding: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.install-popup-div div .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: var(--p-theme-purple);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.1rem;
  cursor: pointer;
}

.install-popup-div div img {
  width: 80%;
  margin-bottom: 20px;
}

.install-popup-div div h1 {
  position: relative;
  margin-top: 20px;
  font-size: 0.7rem;
  color: #444;
  text-align: justify;
}

.install-popup-div div button {
  margin-top: 15px;
  background: var(--p-theme-purple);
  border: none;
  outline: none;
  padding: 5px 30px;
  border-radius: 8px;
  font-size: 0.8rem;
  color: #fff;
}

/* pop up div - end */

/* forms */
label {
  display: block;
  margin: 24px auto;
}

label span {
  display: block;
  margin-bottom: 6px;
}

input,
textarea {
  margin: 4px 0 25px 0px;
  padding: 5px 6px 5px 30px;
  font-size: 0.9em;
  font-style: normal;
  font-weight: lighter;
  color: var(--darkgrey-color);
  width: 100%;
  box-sizing: border-box;
  border: none;
  /* background: var(--yellow-color); */
  border-radius: 4px;
  color: #000;
  border-color: none;
  border-bottom: 2px solid var(--yellow-color);
  outline: none;
}

textarea {
  min-height: 100px;
}

.select {
  width: 100%;
  z-index: 10;
}

.menu {
  /* .css-t3ipsp-control .css-inmdiq5-menu { */
  background: red;
  z-index: 999;
}


.error {
  /* color: var(--red-color); */
  color: #fff;
  border: 1px solid var(--red-color);
  border-radius: 4px;
  padding: 8px !important;
  /* margin-bottom: 15px;
  margin-top: 250px;
  margin-left: auto;
  margin-right: auto; */
  font-size: 0.9rem;
  font-weight: 500;
  /* background: black; */
  background: var(--red-color);
  width: auto;

}

.warning {
  /* color: var(--red-color); */
  color: #fff;
  border: 1px solid var(--red-color);
  border-radius: 4px;
  padding: 8px !important;

  font-size: 0.9rem;
  font-weight: 500;
  /* background: black; */
  background: var(--peach-color);
  width: auto;

}

.success {
  /* color: var(--red-color); */
  color: #fff;
  border: 1px solid var(--red-color);
  border-radius: 4px;
  padding: 8px !important;
  font-size: 0.9rem;
  font-weight: 200;
  /* background: black; */
  background: var(--theme-green);
  width: auto;

}

.large {
  width: 100%;
  border-radius: 0 0 0 0;
}

.small {
  width: 0px;
  display: none;
  border-radius: 0 0 0 0;
}

@media (max-width: 992px) {
  .large {
    width: 0%;
    display: none;
    border-radius: 0 0 0 0;
  }

  .small {
    width: 100%;
    display: block;
    border-radius: 0 0 0 0;
  }
}

/* Form Field Title */
.form-field-title {
  position: relative;
  color: var(--theme-blue);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-field-title span {
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);
  font-size: 1.4rem;
  font-weight: normal;
  color: var(--lightgrey-color);
  ;
}

.form-field-title h1 {
  font-size: 0.9rem;
  font-weight: bolder;
  padding-left: 4px;
  margin-top: -4px;
}

.sign-up-or-div {
  position: relative;
  text-align: center;
}

.sign-up-or-div h1 {
  font-size: 0.9rem;
  color: var(--lightgrey-color);
}

.sign-up-or-div h1::before,
.sign-up-or-div h1::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-200%);
  width: 40%;
  background: var(--lightgrey-color);
  height: 0.5px;
}

.sign-up-or-div h1::before {
  left: 0;
}

.sign-up-or-div h1::after {
  right: 0;
}

.sign-in-with-more-methods-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--cream-color);
  height: 40px;
  padding: 0 10px;
  border-radius: 40px;
  border: 1px solid var(--yellow-color);
  cursor: pointer;
}

.sign-in-with-more-methods-div img {
  width: 20px;
}

.sign-in-with-more-methods-div span {
  padding-left: 10px;
  font-size: 0.9rem;
  color: var(--darkgrey-color);
}

/* popup  */
.pop-up-div {
  opacity: 0;
  pointer-events: none;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: 1s;
}

.pop-up-div.open {
  opacity: 1;
  pointer-events: all;
}

.pop-up-div div {
  position: relative;
  background: var(--white-color);
  padding: 20px;
  border-radius: 22px;
  max-width: 380px;
  width: 100%;
  text-align: center;
}

.pop-up-div div p {
  font-size: 18px;
  color: black;
}

.pop-up-div div p strong {
  color: var(--theme-blue);
}

.addon-service {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #BCECE0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 180px;
  /* width:120px; */
  /* padding-top: 4%; */
  /* padding-bottom: 6%; */
}

.addon-service div .material-symbols-outlined {
  /* padding: 20px 0 0 0; */
  font-size: 2.5rem;
  color: #4CD7D0;
}

.addon-service-h5 {
  /* margin-bottom: 6%; */
  font-size: 0.7rem;
  color: #222;
  font-weight: 600;
  /* padding-top: 5px; */
}

.dashboardCardBoxoffer {
  /* padding: 10px; */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  height: 9rem;
  width: 100%;
  transition: 1s;
  overflow: hidden;
}

@media (max-width: 850px) {
  .dashboardCardBoxoffer {
    height: auto;
  }
}

.dashboardCardBox.active {
  height: 19.5rem;
}

/* OwlCarousel Item */
.owlcarousel-item {
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 100px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.owlcarousel-item div {
  position: relative;
  top: 3px;
}

.owlcarousel-item div .material-symbols-outlined {
  /* padding: 20px 0 0 0; */
  font-size: 2rem;
  color: #4CD7D0;
}

.owlcarousel-item h1 {
  font-size: 1rem;
  color: #666;
  margin: 0;
}

.owlcarousel-item-h5 {
  /* margin-bottom: 6%; */
  font-size: 0.7rem;
  color: #aaa;
  font-weight: 600;
  /* padding-top: 5px; */
}

/* default card */
.default-card {
  position: relative;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px 10px 18px 10px;
}

.default-card .default-card-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.default-card h6 {
  font-size: 0.8rem;
  font-weight: bold;
  color: #888;
}

.default-card h1 {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
}

.default-card div:nth-child(2) {
  text-align: right;
}

.default-card h2 {
  font-size: 1.0rem;
  color: var(--lightgrey-color);
  font-weight: normal;
  margin: 0;
}

.default-card h3 {
  /* position: absolute; */
  /* right: 5px; */
  /* bottom: 5px; */
  font-size: 0.8rem;
  color: var(--lightgrey-color);
  margin: 0;
}

.default-card h4 {
  font-size: 0.7rem;
  color: var(--red-color);
  margin: 0;
}

.default-card h5 {
  font-size: 0.6rem;
  color: #444;
  font-weight: bold;
}

.default-card button {
  height: 25px;
  padding: 0 20px;
  font-size: 0.8rem;
  /* width: 80px; */
  margin: 10px 0 0 0;
}

.default-card img {
  width: 80px;
  margin-bottom: 15px;
}


/* sanskar css start  */
.aflbg {
  background: var(--aflbg);
}

.d_none {
  display: none !important;
}

.section_title_effect {
  font-size: 50px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #00000061;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.sect_padding {
  padding: 80px 0px;

}

.theme_btn {
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  text-align: center;
  border: none;
  align-items: center;
}

.theme_btn.full_width {
  width: 100%;
}

.theme_btn.no_icon {
  padding-right: 0px !important;
  padding: 8px 20px !important;
}

.checkbox_parent {
  margin: 15px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.one_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}



.checkbox_parent input {
  width: inherit;
}

.checkbox_parent label {
  margin: 0px;
  font-size: 14px;
  opacity: 0.7;
}

.checkbox_parent label .click_text {
  font-size: 14px;
}

.modal_btn.theme_btn {
  width: 100%;
}

.theme_btn.btn_white {
  background: white;
  color: #303030;
}

a.theme_btn.btn_white {
  background: white;
  color: #303030;
}

.theme_btn.btn_fill {
  border: none;
  background: var(--theme-green);
  color: var(--white-color);
}

.theme_btn.btn_border {
  background: none;
  color: var(--black-color);
  border: 2px solid var(--theme-green);
}

.theme_btn .btn_arrow {
  position: absolute;
  top: 14px;
  right: 5px;
  font-size: 18px;
}

.ba_animation {
  animation: btn_arrow_animation 1s linear infinite;
}

@keyframes btn_arrow_animation {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-5px);
  }
}



/* form fields css start  */

.form_field {
  margin-bottom: 14px;
  position: relative;
}

.form_field input,
.form_field select,
.form_field textarea {
  border: 1px solid #03468738;
  border-radius: 12px;
  color: #000;
  color: var(--black-color);
  font-size: 16px;
  padding: 10px 10px 10px 42px;
  width: 100%;
}

.field_icon {
  left: 12px;
  position: absolute;
  top: 10px;
}

/* owl navigation css start  */
.owl-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px !important;
}

button.owl-prev,
button.owl-next {
  background: var(--theme-green) !important;
  border-radius: 50% !important;
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

button.owl-prev span,
button.owl-next span {
  line-height: 0px !IMPORTANT;
  position: relative;
  top: -5px;
  color: var(--white-color);
}

form.auth-form input,
form.auth-form textarea,
form.auth-form select {
  border: 1px solid #03468738;
  border-radius: 12px;
  color: #000;
  color: var(--black-color);
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-top: 8px;
}

form.auth-form .css-rd8jxw-control {
  border: 1px solid #03468738;
  border-radius: 12px;
  border-bottom: 1px solid #03468738 !important;
  color: var(--black-color);
  font-size: 16px;
  width: 100%;
  margin-top: 8px;
  background: white !important;
}

form.auth-form .theme_btn {
  margin-top: 15px;
  width: fit-content;
  padding: 10px 22px;
  margin-left: auto;
  margin-right: auto;
}

/* sanskar css end  */


/* sanskar media query css start  */

@media (max-width: 1199px) {
  .reverse-1199 {
    flex-direction: column-reverse;
  }

  .page {
    margin-top: 64px;
  }
}

@media (max-width: 1199px) {
  .reverse-991 {
    flex-direction: column-reverse;
  }
}


@media (max-width: 767px) {
  .theme_btn {
    padding: 6px 12px;
    font-size: 14px;
    padding-right: 30px;
  }

  .theme_btn .btn_arrow {
    top: 9px;
    font-size: 16px;
  }

  .sect_padding {
    padding: 15px 0px;
  }

  .section_title_effect {
    font-size: 35px;
    line-height: 35px;
  }

  .section_title h3 {
    font-size: 22px;
    margin-top: 0px !important;
  }

  .oneline_parent {
    display: block !important;
    white-space: nowrap;
    overflow: auto;
  }

  .op_child {
    display: inline-block;
    white-space: normal;
    width: 80% !important;
    margin-bottom: 8px;
  }

  .owl-nav {
    position: unset;
    transform: unset;
    margin: 0px !important;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 14px !important;
  }

  button.owl-prev,
  button.owl-next {
    position: unset;
  }
}

/* form fields style 2 */
.modal-header,
.modal-body,
.modal-footer {
  padding: 0px;
  border: none;
}

.modal-content {
  border-radius: 18px;
  padding: 15px;
}

.close_modal {
  position: absolute;
  background: #ff3d3d;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  top: 15px;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}

h6.modal_subtitle {
  font-weight: 400;
  font-size: 14px;
  margin-top: 12px !important;
}

.form_field.st-2 {
  margin-top: 16px;
  margin-bottom: 0px;
}

.form_field.st-2 .field_two {
  display: flex;
  gap: 8px;
}

.form_field.st-2 label {
  margin-top: 0px;
  margin-bottom: 6px;
  font-size: 16px;
  color: var(--theme-orange);
  font-weight: 500;
  text-transform: capitalize;
}

.form_field.st-2 select,
.form_field.st-2 input {
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
  /* background-color: #eee; */
  height: 40px;
  font-size: 1rem;
  font-weight: bold;
  color: #666;
  padding: 0 10px;
  border-radius: 0px;
  position: relative;
  padding-right: 40px;
  letter-spacing: 0.1rem;
}

.custom-input::placeholder {
  font-size: 0.8rem;
  font-weight: lighter;
  /* Replace with the desired font */
  color: #888;
  /* Replace with the desired color */
  /* Add any additional styling for the placeholder */
}

.form_field.st-2.new_radio_groups_parent {
  border: 1px solid #d7d7d7;
  padding: 20px 12px;
  border-radius: 7px;
  width: 100%;
  background: white;
  margin: 6px 0;
}

.form_field.st-2.new_radio_groups_parent span.no-floating {
  position: absolute;
  top: -10px;
  left: 20px;
  margin: 0px;
  font-size: 0.7rem;
  letter-spacing: 0.9px;
  font-weight: normal;
  color: var(--p-theme-orange);
  font-weight: 600;
  border: none;
  border-radius: 5px 5px 5px 0px;
  padding: 3px 12px;
  box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.form_field.st-2 select {
  background: #fffffa;

}

.form_field.st-2 textarea {
  padding: 10px 42px 10px 10px;
}

.form_field.st-2 .field_inner,
.form_field.st-2 .field_inner.select {
  position: relative;
}

.form_field.st-2 .field_inner.i_and_s {
  display: flex;
}

.fl_form_field .field_inner select {
  background: #eeeeee;
}

.form_field.st-2 .field_inner.i_and_s select {
  padding-right: 0px;
  width: 100%;
}

.form_field.st-2 .field_inner.i_and_s input {
  padding-right: 10px;
  width: 100%;
}

select {
  cursor: pointer;
}

.form_field.st-2 .field_inner.i_and_s .inner_select {
  width: 150px;
}

.form_field.st-2 .field_inner.select {
  cursor: pointer;
}

.form_field.st-2 .field_inner.select:after {
  content: '';
  width: 20px;
  height: 40px;
  background: #efefef;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 1px solid #ddd;
}

.form_field.st-2 .field_icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--p-theme-grey);
  font-size: 1.8rem;
  pointer-events: none;
  left: inherit;
  z-index: 9;
}

.form_field.st-2 .field_icon .text_icon {
  width: 30px;
  height: 30px;
  text-align: center;
  background: #eee;
  margin-right: -10px;
  margin-bottom: -10px;
  font-size: 15px;
  color: #474747;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_field.st-2 h6.dec_related_to_input {
  font-size: 12px;
  color: #666;
  margin: 2px 0px !important;
}

.form_field.st-2 .radio_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.form_field.st-2 .radio_group_single label {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 35px;
  border-radius: 20px;
  padding: 6px 7px;
  font-size: 0.9rem;
  font-weight: 500;
  color: rgb(0 0 0);
  transition: 0.3s;
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 0px;
  padding-left: 38px;
  background: #efefef;
}

.form_field.st-2 .radio_group_single .radiochecked label {
  background: var(--p-theme-green);
  color: white;
  border-color: var(--p-theme-green);
}

.form_field.st-2 .radio_group_single label .radio_icon {
  position: absolute;
  line-height: 0px;
  left: 6px;
  top: 50%;
  transform: translateY(-41%);
}

.form_field.st-2 .radio_group_single label .radio_icon .check {
  display: none;
}

.form_field.st-2 .radio_group_single .radiochecked label .radio_icon .check {
  display: block;
}

.form_field.st-2 .radio_group_single .radiochecked label .radio_icon .add {
  display: none;
}

.form_field.st-2 .radio_group_single .custom_radio_button {
  position: relative;
}

.form_field.st-2 .css-1no06ky-control {
  border: none;
  height: 40px;
}

.form_field.st-2 .css-1fdsijx-ValueContainer {
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
  height: 40px !important;
}

.form_field.st-2 .css-1jqq78o-placeholder {
  height: 40px;
  margin: 0px;
  padding-top: 9px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  background: #efefef;
  padding-left: 12px;
}

.single_card {
  height: 50px;
  margin: 10px 0px;
  background: var(--white-color);
  padding: 10px;
}

.form_field.st-2 .css-1jqq78o-placeholder:focus {
  border: none;
  outline: none;
}

.form_field.st-2 .css-1rrxwye-control:hover {
  border: none !important;
  box-shadow: none !important;
}

.form_field.st-2 .css-qbdosj-Input {
  height: 40px;
  margin: 0pc !IMPORTANT;
  padding: 0px !important;
}

.form_field.st-2 .radio_group_single .custom_radio_button input {
  position: absolute;
  height: 35px;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

h2.pg_title {
  font-size: 24px;
  color: var(--theme-orange);
  text-transform: uppercase;
}

h2.card_title {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px !important;
  text-transform: uppercase;
}

.d_inner_card {
  background: var(--white-color);
  padding: 20px 15px;
  margin-top: 10px;
}

.divider {
  background: #dddddd;
  height: 1px;
  margin: 8px 0px;
}

.property_image .property-img-container {
  width: 100%;
  height: 200px;
  position: relative;
  background: #fff;
  border: 1px solid #333C5D;
  border-radius: 10px 10px 0 0;
  padding: 0;
  overflow: hidden;
  margin-top: 25px;
}

.property_image .property-img-container img {
  width: 100%;
  object-fit: cover;
}

.d_block_991 {
  display: none;
}

.property_image .property-img-container span {
  position: absolute;
  top: 8px;
  background: #ddd;
  padding: 4px;
  border-radius: 6px;
  font-size: 20px;
  cursor: pointer;
}

.property_image .property-img-container span.delete {
  right: 8px;
}

.property_image .property-img-container span.upload {
  right: 48px;
}

.property_image h4.property_desc {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  padding: 10px 0px !important;
}

.property_image h4.property_desc .indicating-letter {
  position: absolute;
  left: 0;
  bottom: 3px;
  height: 1px;
  background: #333C5D;
  width: 30px;
}

.property_image h4.property_desc .indicating-letter::before,
.property_image h4.property_desc .indicating-letter::after {
  position: absolute;
  top: -2px;
  content: '';
  width: 4px;
  height: 4px;
  background: #aaa;
  border-radius: 50%;
}

.property_image h4.property_desc .indicating-letter::before {
  left: 35px;
}

.property_image h4.property_desc .indicating-letter::after {
  left: 42px;
}

.pgadmindasboard,
.dashboard_pg {
  margin-top: 68px;
}

.set_bg_img_with_overlay {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  backdrop-filter: blur(10px);
  position: relative;
}

.set_bg_img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  backdrop-filter: blur(10px);
  position: relative;
}

.set_bg_img_with_overlay:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  top: 0;
  left: 0;
  opacity: 0.5;
}

.blur-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}

.fl_form_field.type_file input {
  padding-top: 0px !important;
}

.fl_form_field.type_file label {
  margin: 25px 0px 6px 0px !important;
}

@media only screen and (min-width: 1200px) {
  .top_header_pg {
    padding-top: 68px;
    min-height: 100vh;
  }
}

/* sanskar media query css end  */



/* Propagent css start  */

/* roboto font family css start  */
@font-face {
  font-family: r_regular;
  src: url(../public/assets/font/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: r_light;
  src: url(../public/assets/font/roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: r_thin;
  src: url(../public/assets/font/roboto/Roboto-Thin.ttf);
}

@font-face {
  font-family: r_medium;
  src: url(../public/assets/font/roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: r_bold;
  src: url(../public/assets/font/roboto/Roboto-Bold.ttf);
}

/* roboto font family css end  */

/* color variable  */
body {
  font-family: r_regular !important;
  min-height: 100vh;
  background: #f5f5f5 !important;
}




:root {
  --p-theme-purple: #5A10D5;
  --p-theme-orange: #FA6304;
  --p-theme-grey: #888888;
  --p-theme-green: #00a8a8;
  --p-theme-green-bg: #e2ffe7;
  --white-color: #fff;
  --black-color: #000;
  --light-black: #303030;
  --p-theme-purple-bg: #efe5ff;
  --p-theme-orange-bg: #ffefe5;

}

.verticall_gap {
  height: 22px;
}


.pa_bg {
  background-color: #f5f5f5;
}

.pa_inner_page {
  padding: 0px 22px;
  margin-top: 22px;
}

h2.p_title {
  font-size: 21px !important;
  font-family: r_medium;
  margin-bottom: 4px !important;
}

.about_content .abc_left h2.p_title {
  margin-bottom: 4px !important;
}

h4.p_subtitle {
  font-size: 16px;
  color: #303030;
}

.verticall_gap_991,
.verticall_gap_575 {
  display: none;
}

/* propagent home page css start  */
.how_it_work {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.how_it_work .hiw_single {
  width: 25%;
  background: #ffddc8;
  padding: 30px;
  border-radius: 0px;
  position: relative;
  text-align: center;
  color: #FA6304;
  overflow: hidden;
}

.how_it_work .hiw_single .steps {
  position: absolute;
  top: 40px;
  left: 0;
  background: #f7f7f7;
  transform: rotate(-45deg);
  width: 100%;
  transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
}

.how_it_work .direction {
  width: 12.5%;
  text-align: center;
  font-size: 22px;
  color: var(--p-theme-orange);
}

.propagent_home_search .field_icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  line-height: 0pc;
  left: inherit;
}

.propagent_home_search input {
  padding: 5px 50px 5px 12px;
}

.home_pending_property {
  background: ehite;
  background: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 15px;
  padding-right: 40px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.home_pending_property .left {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: var(--p-theme-purple);
}

.max-991 {
  max-width: 991px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.info_in_ul_li li {
  margin-top: 15px !important;
  font-size: 16px;
  opacity: 0.9;
}

.info_in_ul_li li p {
  font-size: 14px;
  opacity: 0.8;
}

.home_pending_property span.material-symbols-outlined {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.home_pending_property .right h6 {
  color: var(--p-theme-grey);
  font-size: 16px;
}

.home_pending_property .right h5 {
  font-size: 30px;
  font-family: 'r_medium';
  margin-top: 10px !important;
}

.home_pending_property .left img {
  width: 100%;
}

.hiw_single .icon span {
  font-size: 40px;

}

section.top_cities.sect_padding {
  background: #f7f7f7;
}

.hiw_single h5 {
  font-size: 18px;
}

.hiw_single h5 {
  font-size: 20px;
}

section.work_flow.sect_padding {
  background: #f7f7f7;
}

.mabc_right ul li {
  list-style-type: disc;
  margin-left: 20px !important;
  margin-top: 6px !important;
}

.pcs_inner .pcs_image_area img.bigimage {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 12px 0px 0px 12px;
  /* height: 100%; */
}

.pmd_body {
  margin-top: 8px;
}

.pmd_top {
  display: grid;
  gap: 6px;
}

.card_detail_lm {
  display: none;
}

.property_card_single .head_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pcs_other_info.propagent_details .single_user .user_img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 0 auto;
}



.pcp_single .contacts {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
  border-top: 1px solid #e7e7e7;
  margin-top: 15px;
  gap: 15px;
}

.pcs_inner .pcs_main_detail {
  width: 75%;
  padding: 16px 16px 16px 0px;
}

.pcs_inner {
  width: 80%;
  display: flex;
  gap: 16px;
}

.pcs_inner .pcs_image_area {
  width: 25%;
  overflow: hidden;
}

.property_card_single a.prop_edit {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 34px;
  height: 34px;
  background: var(--white-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--p-theme-purple);
}

.pcs_inner .pcs_image_area .purpose {
  position: absolute;
  top: 40px;
  left: 0;
  background: #f5f5f5;
  transform: rotate(-45deg);
  width: 100%;
  transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
  text-align: center;
  font-size: 16px;
}

a.click_text {
  cursor: pointer !important;
}

.click_text {
  font-size: 16px;
  color: var(--p-theme-purple);

}

.click_text:hover {
  color: var(--p-theme-purple);
}

.b_menu_single .menu_icon {

  width: 35px;
  height: 35px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.b_menu_single.b_menu_active .menu_icon {
  background: var(--p-theme-orange-bg);
}

.b_menu_single.b_menu_active .menu_icon span {
  color: var(--p-theme-orange);
}

.b_menu_single.b_menu_active .menu_name {
  font-family: r_medium;
  color: var(--p-theme-orange);
}

.b_menu_single.profile .menu_icon {
  background: var(--p-theme-purple);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
}

.b_menu_single.b_menu_active.profile .menu_icon {
  background: var(--p-theme-orange);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
}

li.main_menus {
  display: flex;
  width: 70%;
  justify-content: center;
  gap: 30px;
}

.menu_single {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--p-theme-grey);
  transition: 0.3s;
  gap: 5px;
}

.menu_single span {
  width: 35px;
  height: 35px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--p-theme-grey);
  border-radius: 6px;
  transition: 0.3s;
}

.menu_single:hover {
  color: var(--p-theme-orange);
  /* font-family: r_medium; */
  transition: 0.3s;
}

.menu_single:hover span {
  background: var(--p-theme-orange-bg);
  color: var(--p-theme-orange);
  transition: 0.3s;
}

.menu_single.active {
  color: var(--p-theme-orange);
  font-family: r_medium;
  transition: 0.3s;
}

.menu_single.active span {
  background: var(--p-theme-orange-bg);
  color: var(--p-theme-orange);
  transition: 0.3s;
}

.menu_single.login {
  color: var(--p-theme-purple);
}

.menu_single.login:hover {
  color: var(--p-theme-orange);
  /* font-family: r_medium; */
  transition: 0.3s;
}

.menu_single.login.active {
  color: var(--p-theme-orange);
  font-family: r_medium;
  transition: 0.3s;
}

.menu_single.login:hover span {
  background: var(--p-theme-orange-bg);
  color: var(--p-theme-orange);
  transition: 0.3s;
}

.menu_single.login.active span {
  background: var(--p-theme-orange-bg);
  color: var(--p-theme-orange);
  transition: 0.3s;
}

.menu_single.login span {
  background: var(--p-theme-purple-bg);
  color: var(--p-theme-purple);
  transition: 0.3s;
}

.menu_single.profile span {
  font-size: 14px;
  max-width: 100px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  margin-right: 6px;
  width: inherit;
  height: inherit;
  color: var(--white-color);
}

.pa_inner_page .sc_first_row {
  justify-content: space-between;
}

.pa_inner_page .sc_first_row .form_field.st-2 {
  margin: 0px;
}

.pa_inner_page .sc_first_row .radio_group_single {
  width: 100px;
}

.menu_single.profile {
  border-radius: 50px;
  padding: 3px 3px 3px 14px;
  transition: 0.3s;
  border: 1px solid var(--p-theme-purple);
}

.menu_single.profile.active span {
  background: none;
  color: var(--white-color);
}

.menu_single.profile:hover span {
  background: none;
  color: var(--white-color);
}

.pg_tc {
  position: relative;
  padding-bottom: 82px;
}

.menu_single.profile:hover {
  background: var(--p-theme-orange-bg) !important;
  border: 1px solid;
}

.otp_pg .fl_form_field.input_otp {
  position: relative;
  width: 100%;
  height: 25px;
}

.otp_pg .fl_form_field.input_otp img {
  width: 270px;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.pcs_other_info.property_view_demand {
  flex-direction: column;
  gap: 10px;
  background: none;
  border-left: 1px solid var(--p-theme-grey);
}

.pcs_other_info.property_view_demand h6 {
  text-align: center;
}

.pcs_other_info.property_view_demand .view {
  background: var(--p-theme-orange-bg);
  padding: 22px;
  border-radius: 15px;
  border: 1px solid rgba(250, 99, 4, 0.7);
}

.pcs_other_info.property_view_demand h6 {
  font-size: 1rem;
  opacity: 0.7;
  padding-top: 5px !important;
  margin-top: 5px !important;
}

.pcs_other_info.property_view_demand h5 img {
  width: 28px;
}

.pcs_other_info.property_view_demand .demand h5 {
  color: #1c832d;
}

.pcs_other_info.property_view_demand h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.pcs_other_info.property_view_demand .view h5 {
  color: var(--p-theme-orange);
}

.pcs_other_info.property_view_demand .view h6 {
  color: var(--p-theme-orange);
  border-top: 1px solid rgba(250, 99, 4, 0.7);
}

.property_card_single {
  border: 1px solid var(--p-theme-grey);
}

.pcs_other_info.property_view_demand .demand h6 {
  color: var(--p-theme-green);
  border-top: 1px solid rgba(94, 171, 107, 0.7);
}

.pcs_other_info.property_view_demand .demand {
  background: var(--p-theme-green-bg);
  padding: 22px;
  border-radius: 15px;
  border: 1px solid rgba(94, 171, 107, 0.7);
}

.otp_pg .fl_form_field.input_otp input {
  border: none;
  padding: 0px;
  width: 100%;
  height: 25px;
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  /* padding: 0px 15px; */
  font-size: 25px;
  color: #303030;
  letter-spacing: 25px;
}


.otp_input_label h5 {
  font-size: 14px;
  color: #909090;
}

.otp_input_label h6 {
  font-size: 14px;
  color: #a976ff;
}

.otp_input_label {
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding-bottom: 6px;

}

.otp_input_parent {
  border: 1px solid #d7d7d7;
  padding: 6px 12px 6px 12px;
  border-radius: 7px;
  margin-top: 14px;
}

.menu_single.profile:hover span {
  color: var(--p-theme-orange);
}

.otp_pg h4.title span {
  opacity: 0.7;
  font-size: 16px;
}

a.menu_single.profile.pointer.active {
  background: var(--p-theme-orange-bg) !important;
  border: 1px solid;
}

a.menu_single.profile.pointer.active span {
  color: var(--p-theme-orange);
}

.propagentusersingle {
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  background: var(--white-color);
  border-radius: 15px;
  padding: 10px 15px;
  position: relative;
}

.propagentusersingle.highlight {
  background: lightgoldenrodyellow;
}

.ppc_single_bottom {
  position: relative;
}

.ppc_single_bottom .toggle-switch.off.residential div {
  border: 1px solid var(--p-theme-green);
  background: var(--p-theme-green-bg);
}

.ppc_single_bottom .toggle-switch.off.residential div div {
  background: var(--p-theme-green);
}

.ppc_single_bottom .residential-commercial-switch {
  position: absolute;
  bottom: 0px;
  right: 0;
  top: inherit !important;
}

.propagent_myproperties .ppc_single_bottom .residential-commercial-switch {
  position: inherit;
}

.upcoming_commercial {
  opacity: 0.3;
  position: relative;
}

.upcoming_commercial:after {
  content: 'Upcoming';
  position: absolute;
  left: 14px;
  bottom: 6px;
}

.next_btn_back {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  background: #f5f5f5;
  padding: 12px 22px;
}

.pb_for_nextbtn {
  padding-bottom: 60px;
}

.propagentuser.propagentnotification .propagentusersingle {
  display: block;
  position: relative;
}

h4.notification_date {
  font-size: 12px;
  position: absolute;
  top: 6px;
  right: 10px;
  color: var(--p-theme-grey);
}

h4.notification_date img {
  height: 26px;
  width: auto;
}

.propagentcontactdetail .propagentusersingle h5.name,
.propagentcontactdetail .propagentusersingle h6.phone_number {
  padding-right: 30px !important;
}

.propagentcontactdetail h4.notification_date {

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.propagentnotification .propagentusersingle .left .img_div img {
  object-fit: contain;
}

.propagentusersingle .left .img_div {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 2px;
  margin: 2px;
}

.propagentusersingle .left {
  display: flex;
  align-items: center;

  width: 100%;
}

.propagentnotification .propagentusersingle .left {
  align-items: start;
  margin-top: 7px;
}

.propagentnotification .expand_line {
  position: absolute;
  font-size: 12px;
  color: #a976ff;
  font-family: 'r_medium';
  right: 10px;
  bottom: 6px;
}

.role_dropdown .form_field select {
  padding: 5px;
  border-radius: 7px;
  font-size: 14px;
}

.role_dropdown .form_field {
  margin-bottom: 0px;
  margin-top: 10px;
}

.propagentusersingle h5.name {
  font-size: 16px;
  font-family: 'r_medium';
  word-break: break-all;
}

.propagentusersingle .toggle-switch div {
  width: 36px;

}

.propagentusersingle .toggle-switch div div {
  width: 16px;
}

.propagentusersingle .residential-commercial-switch span {
  font-size: 10px;
}

.propagentusersingle .toggle-switch.off.residential div {
  border: 1px solid var(--p-theme-green);
  background: var(--p-theme-green-bg);
}

.propagentusersingle .toggle-switch.off.residential div div {
  background: var(--p-theme-green);
}

.propagentusersingle .toggle-switch.on.commercial div {
  border: 1px solid var(--p-theme-grey);
}

.propagentusersingle .toggle-switch.on.commercial div div {
  background: var(--p-theme-grey);
}

.propagentusersingle .my_switch {
  position: absolute;
  top: 40px;
  right: 15px;
}



.propagentusersingle h6.phone_number {
  font-size: 14px;
  color: var(--p-theme-grey);
  margin-top: 2px !important;
}

h6.phone_number.contact_message {
  color: #1e1e1e;
  border-top: 1px solid #e5e5e5;
  margin-top: 5px !important;
  padding-top: 5px !important;
  margin-bottom: 8px !important;
}

.propagentnotification .propagentusersingle h6.phone_number {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.propagentnotification .propagentusersingle.showmore h6.phone_number {
  display: block;
}

.propagentusersingle .left .img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.propagentuser {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 22px;
  grid-row-gap: 22px;
}

button.theme_btn.btn_fill.confirm {
  background: var(--p-theme-grey);
}

.rmf_inner.inactiveuser img {
  width: 115px;
  margin: auto;
  position: absolute;
  top: -72px;
  left: 0;
  right: 0;
}

.rmf_inner.inactiveuser {
  padding-top: 60px;
  text-align: center;
}

.rmf_inner.inactiveuser h4 {
  font-size: 22px;
  color: #303030;
}

.rmf_inner.inactiveuser h4 span {
  font-size: 28px;
  text-transform: capitalize;
  color: var(--p-theme-orange);
}

.rmf_inner.inactiveuser h6 {
  margin-top: 2px !important;
  color: var(--p-theme-grey);
  font-size: 16px;
}

.rmf_inner.inactiveuser h6 span {
  font-family: r_medium;
  cursor: pointer;
}

.ppc_single_parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 22px;
}

.ppc_single {
  padding: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background: white;
  border-radius: 22px;
  margin: 10px 0;
}

.ppc_single_middle {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 8px;
  margin: 12px 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-right: 35px;
}

.ppc_single_middle.addtional_rooms {
  display: block;
}

.addtional_rooms .ppcsm_single .left h5 span {
  background: #dfdfdf;
  border-radius: 30px;
  padding: 4px 12px;
  font-size: 12px;
  margin-top: 2px;
}

.ppc_single_top .prop_edit {
  background: var(--p-theme-purple);
  width: 32px;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}


.ppc_single_top .prop_edit span {
  font-size: 18px;
}

.addtional_rooms .ppcsm_single .left h5 {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}


.ppcsm_single {
  display: flex;
  gap: 8px;
}

.ppc_single_bottom .img_container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.ppc_single_bottom .img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ppcsm_single .icon_container img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  opacity: 0.9;
}

.ppcsm_single .left h6 {
  font-size: 11px;
  font-family: r_regular;
  color: #606060;
  text-transform: uppercase;
}

.ppcsm_single .left h5 {
  font-size: 13px;
  font-family: r_medium;
  color: #303030;
  margin-top: 2px !important;
}

.ppc_single_top {
  display: flex;
  gap: 15px;
}

.ppc_single .ppc_single_top .img_container {
  width: 25%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 3/2;
}

.ppc_single_bottom .toggle-switch div {
  width: 36px;
}

.ppc_single_bottom .toggle-switch div div {
  width: 16px;
}

.ppc_single .ppc_single_top .img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ppc_single .show_more_arrow span {
  font-size: 18px;
}

.ppc_single_top {
  gap: 8px;
}

.ppc_single .full_address h6 {
  font-size: 13px;
}

.ppc_single h5.demand {
  font-size: 16px;
  font-family: 'r_bold';
  color: #303030;
  margin-bottom: 5px !important;
}

.ppc_single_bottom {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.ppc_single_top .left_side {
  width: 75%;
}

.ppc_single_bottom .property_owner_detail {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ppc_single_bottom h5 {
  font-size: 16px;
  font-family: 'r_medium';
  color: #303030;
}

.ppc_single .show_more_arrow {
  background: #e1e1e1;
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.ppc_single .show_more_arrow span {
  color: #606060;
}

.ppc_single_bottom h6 a.phone {
  font-size: 14px;

  color: var(--p-theme-grey);
  margin-top: 2px !important;
}

.ppc_single .full_address {
  display: grid;
  gap: 2px;
}

.ppc_single .full_address h6 {
  font-size: 14px;
  color: #373737;

}

.property_active_inactive .toggle-switch.off.residential div {
  border: 1px solid var(--p-theme-green);
  background: var(--p-theme-green-bg);
}

.my_all_prop .toggle-switch.on div {
  background: var(--p-theme-purple);
}

.my_all_prop .toggle-switch.on div div {
  background: #fff;
}

.my_all_prop .toggle-switch.off div {
  background: var(--p-theme-orange);
  border: 1px solid var(--p-theme-orange);
}

.my_all_prop .toggle-switch.off div div {
  background: white;
  border: 1px solid white;
}

.property_active_inactive .toggle-switch.off.residential div div {
  background: var(--p-theme-green);
}

.property_active_inactive .toggle-switch.on.commercial div {
  border: 1px solid var(--p-theme-grey);
  background: #efefef;
}

.property_active_inactive .toggle-switch.on.commercial div div {
  background: var(--p-theme-grey);
}

.user_name_parent {
  width: 80%;
}

.property_owner_detail .pod_right {
  width: 90%;
}

.my_prop_card {
  padding: 22px;
  border-radius: 22px;
  background: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.my_all_filter .residential-commercial-switch {
  justify-content: center;
}

/* how use this app page css start  */
.app_videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22px;
}

.blog_sect.app_videos .card-container {
  margin-top: 0;
}

.blog_sect.app_videos .card-image video {
  aspect-ratio: 3 / 2;
  background-size: cover;
  width: 100%;
}

.blog_sect.app_videos .card-body p {
  margin-bottom: 0px !important;
}

.blog_sect.app_videos .card-body h3 {
  margin-top: 0px !important;
  font-family: 'r_medium';
  font-size: 18px;
}


/* how use this app page css end  */
@media only screen and (max-width: 1199px) {
  .propagentuser {
    grid-template-columns: repeat(2, 1fr);
  }

  .pa_inner_page {
    padding-top: 22px;
    margin-top: 0px;
  }

  .pg_tc {
    padding-bottom: 100px;
  }

  .full-content.page {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .d_none_991 {
    display: none;
  }

  .d_block_991 {
    display: block;
  }

  .ppc_single_parent {
    grid-template-columns: repeat(1, 1fr);
  }

  .pcs_other_info.property_view_demand {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px solid var(--p-theme-grey);
    border-left: none;
  }

  .pcs_other_info.property_view_demand .view,
  .pcs_other_info.property_view_demand .demand {

    padding: 10px;

  }

  .verticall_gap_991 {
    display: block;
    height: 22px;
  }

  .flex_direction_991 {
    flex-direction: column-reverse;
  }

  .app_videos {
    grid-template-columns: repeat(2, 1fr) !important;
  }

}

@media only screen and (max-width: 767px) {
  .top_margin_767 {
    margin-top: 15px;
  }

  .all_tickets_left .search-select {
    top: 52px !important;
  }

  .propagentuser {
    grid-template-columns: repeat(1, 1fr);
  }



  .otp_pg .checkbox_parent {
    justify-content: center;
  }

  .otp_pg .fl_form_field.input_otp input {
    width: 100%;
  }

  .sc_first_row {
    justify-content: center;
  }

  .info_in_ul_li li {
    font-size: 14px;
  }

  .info_in_ul_li li p {
    font-size: 12px;
  }

  .rmf_inner .theme_btn {
    /* margin-top: 65px; */
    min-height: 50px;
    font-size: 20px;
  }

  .home_pending_property .right h6 {
    font-size: 14px;
  }

  .home_pending_property .right h5 {
    font-size: 22px;
    margin-top: 4px !important;
  }

  .page {
    margin-top: 50px;
  }

  .d_none_767 {
    display: none;
  }

  .how_it_work {
    display: block;
  }

  .how_it_work .hiw_single {
    width: 100%;
    margin-bottom: 10px;
    padding: 25px;
  }

  .how_it_work .hiw_single:last-child {
    margin-bottom: 0px;
  }

  .hiw_single h5 {
    font-size: 18px;
  }


}

@media only screen and (max-width: 575px) {
  .blog_sect.app_videos .card-body {
    padding: 8px 15px !important;

  }

  .my_prop_card.my_all_filter {
    box-shadow: none;
  }

  .project-filter nav button:nth-last-child(1) {
    border-radius: 0px !important;
  }

  .project-filter nav button:nth-child(1) {
    border-radius: 0px !important;
  }

  .project-filter {
    margin-left: -15px;
    margin-right: -15px;
  }

  .my_prop_card {
    padding: 15px;
    border-radius: 15px;
  }

  .ppc_single_bottom .residential-commercial-switch {
    position: inherit;
  }

  /* .ppc_single_bottom .img_container {
    width: 40px;
    height: 40px;

  } */

  .ppc_single_bottom h5 {
    font-size: 14px;

  }

  .ppc_single_bottom {
    flex-direction: column;
    align-items: inherit;
  }

  .ppc_single_bottom .property_owner_detail {
    width: 100%;
    position: relative;
  }

  .property_owner_detail a.whatsapp {
    position: absolute;
    right: 0px;
    bottom: 2px;
  }

  .ppc_single_bottom h6 a.phone {
    font-size: 12px;

  }

  .property_owner_detail a.call.whatsapp {
    right: 30px;
  }

  .ppc_single_bottom .residential-commercial-switch span {
    font-size: 10px !important;
  }

  .propagentusersingle .toggle-switch div,
  .ppc_single_bottom .toggle-switch div {
    height: 18px;
  }

  .propagentusersingle .toggle-switch div div,
  .ppc_single_bottom .toggle-switch div div {
    width: 12px;
    height: 12px;
  }

  .propagentusersingle {
    box-shadow: none;
    border-radius: 0px;
    margin: 0px -15px;
  }

  .propagentuser {
    grid-column-gap: 0px;
    grid-row-gap: 15px;
  }

  h4.notification_date {
    top: 5px;
    right: 15px;
  }

  .propagentusersingle .left .img_div {
    width: 55px;
    height: 55px;
  }

  .propagentusersingle .left {
    gap: 5px;
  }

  .propagentusersingle h5.name {
    font-size: 14px;
  }

  .propagentnotification .expand_line {
    right: 15px;
    bottom: 2px;
  }

  .propagentusersingle h6.phone_number {
    font-size: 12px;

  }


  .ppcsm_single .icon_container img {
    width: 22px;
    height: 22px;

  }

  .ppc_single_middle {
    gap: 6px;
    margin-right: 28px;
  }

  .ppcsm_single {
    gap: 4px;
  }

  /* .ppcsm_single .icon_container {
    display: none;
  } */

  .ppc_single_top .prop_edit {
    top: -13px;
  }

  .fav_and_share {
    top: -5px;
  }

  .ppc_single {
    padding: 15px;
    box-shadow: none;
    border-radius: 0px;
    margin-right: -15px;
    margin-left: -15px;
    border-bottom: 1px solid #b1b1b1;
  }

  .ppc_single .ppc_single_top .img_container {
    width: 25%;
    height: 100px;
    border-radius: 6px;
  }

  .pcs_other_info.propagent_details .item.pcp_single {
    display: flex;
    gap: 15px;
    justify-content: center;
  }

  .pcs_other_info.propagent_details {
    display: block !important;
  }

  .pcs_other_info.propagent_details .pcp_single .contacts {
    display: flex;
    justify-content: space-around;
    padding-top: 0px;
    border-top: none;
    margin-top: 0px;
    gap: 5px;
    flex-direction: column;
  }

  .pcs_other_info.propagent_details .pcp_single .contacts_single h6 {
    display: none;
  }

  .copyright-area.copyright_bottom {
    margin-bottom: 0px;
    position: absolute;
    width: 100%;
    bottom: 60px;
  }

  .card_detail_lm {
    display: block;
  }

  .pa_inner_page {
    padding-top: 15px !important;
    padding: 15px !important;
  }

  .verticall_gap_575 {
    display: block !important;
  }

  .verticall_gap,
  .verticall_gap_991,
  .verticall_gap_575 {
    height: 15px !important;
  }

  .bg_575 {
    background: #fa63041a !important;
  }

  .self_property_detail {
    grid-row-gap: 15px !important;
    grid-column-gap: 15px !important;
  }

  .self_property_detail .spd_single .left {
    width: 50px !important;
    height: 50px !important;
    border-radius: 15px !important;
    padding: 8px !important;
    margin-top: -25px !important;
  }

  .self_property_detail .spd_single {
    border-radius: 20px !important;
    margin-top: 10px !important;
    padding: 15px !important;
    gap: 10px !important;

  }

  .self_property_detail .spd_single .right h6 {
    font-size: 14px !important;
  }

  .self_property_detail .spd_single .right h5 {
    font-size: 22px !important;
    margin-top: 4px !important;
  }

  .properties_map {
    border-radius: 20px !important;
    padding: 15px !important;
  }

  h2.p_title {
    font-size: 18px !important;
  }

  .about_content .abc_left h2.p_title {
    font-size: 18px !important;
    margin-bottom: 4px !important;
  }

  h4.p_subtitle {
    font-size: 14px !important;
  }

  .pi_cities_single h6 {
    font-size: 14px !important;
  }

  .pi_cities_single h5 {
    font-size: 16px !important;
  }

  .pi_cities_single .bar {
    width: 95% !important;
  }

  .pi_cities_single {
    margin-top: 18px !important;
  }

  .propagent_dashboard_inner .property_details {
    grid-column-gap: 15px !important;
    grid-row-gap: 15px !important;
  }

  .propagent_dashboard_inner .property_details .property_detail_single {

    border-radius: 20px !important;
    padding: 15px !important;
    display: flex !important;
    gap: 0px !important;
    justify-content: inherit !important;
    flex-direction: column !important;
  }

  .property_detail_single .left h6 {
    font-size: 14px !important;

  }

  .property_detail_single .left h5 {
    font-size: 22px !important;
    margin-top: 4px !important;
  }

  .property_detail_circular-progress {
    width: 60%;
    margin-top: 8px;
  }

  .app_videos {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .app_videos {
    gap: 15px;
  }
}

/* may be remove css  */
.fl_form_field .field_icon {
  display: none;
}

.form-w {
  width: 100% !important;
}

.theme_btn.btn_fill {
  border: none;
  background: var(--p-theme-purple);
  color: var(--white-color);
  overflow: hidden;
}

/* .theme_btn.btn_fill:hover::before {
  width: 100%;
} */

/* Animation Styles */
/* .theme_btn.btn_fill::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--p-theme-green);
  transition: width 0.4s ease;
} */
.typing_effect .AutoTypingEffect {
  color: var(--p-theme-orange) !important;
}

.logo_carousel {
  background: var(--p-theme-orange) !important;
}

button.owl-prev,
button.owl-next,
.menu_single.profile {
  background: var(--p-theme-purple) !important;
}

button.owl-prev span,
button.owl-next span {
  top: -2px !important;
}


/* chatbox css start  */
.chat_user_img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.chat_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat_box_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.chat_box_header .img_and_name {
  display: flex;
  align-items: center;
  gap: 7px;
}

.chat_box_header .mobile_chat_back_button {
  display: none;
}

.chat_box_header .mobile_chat_back_button small {
  font-size: 1rem;
  font-weight: bold;
}

.chat_box_header .mobile_chat_back_button span {
  font-size: 1.1rem;
}

.ticket_detail.chat_box {
  height: calc(100vh - 68px);
  position: relative;
}

.chat_box_footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.chat_box_header,
.chat_box_footer {
  padding: 6px 12px;
  background: #eeeeee;
}

.chat_box_header {
  border-bottom: 2px solid var(--p-theme-orange);
}

.chat_box_footer {
  border-top: 2px solid var(--p-theme-orange);
}

.chat_box_body {
  padding: 12px;
  height: calc(100vh - 125px);
  overflow-y: auto;
  padding-bottom: 80px;
}

.chat_box_body::-webkit-scrollbar {
  width: 4px;
  /* Adjust the width as needed */
}

.chat_box_body::-webkit-scrollbar-thumb {
  background-color: #ced0d1
    /* Adjust the scroll thumb color as needed */
}

.chat_box_body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Adjust the scrollbar track color as needed */
}

.chat_box_body .my_chat {
  width: 65%;
  margin-left: auto;
}

.chat_box_body .partner_chat {
  width: 65%;
  margin-right: auto;
}

.fl_form_field select:focus-visible {
  outline: none !important;
}

.fl_form_field input:focus,
.fl_form_field select:focus {
  border-color: var(--p-theme-grey);
}

.chat_single {
  margin-top: 4px;
  padding: 5px;
  border-radius: 8px;
  font-size: 13px;
  position: relative;
  width: fit-content;
  padding-bottom: 14px;
  min-width: 120px;
}

.my_chat .chat_single {
  background: var(--p-theme-purple-bg);
  margin-left: auto;
}

.partner_chat .chat_single {
  background: var(--p-theme-orange-bg);
  margin-right: auto;
  position: relative;
  margin-left: 25px;
}

.chat_single .time {
  font-size: 9px;
  position: absolute;
  right: 5px;
  bottom: 2px;
  color: var(--p-theme-grey);
}

.send_icon {
  position: absolute;
  right: 15px;
  top: 50%;
  width: 42px;
  height: 42px;
  background: #5a10d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}

.new_ticket {
  background: var(--p-theme-purple);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.send_icon span,
.new_ticket span {
  color: white;
}

.partner_chat .chat_single .support_img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -25px;
}

.partner_chat .chat_single .support_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.partner_chat .chat_single h5 {
  font-size: 12px;
  background: #ffddc7;
  margin: -5px -5px 0px -5px !important;
  padding: 5px !important;
  font-family: r_medium;
  border-radius: 0px 8px 0px 0px;
}



.partner_chat .chat_single:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 10px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background: #ffddc7;
  top: 0;
  left: -7px;
}

.chat_box_footer input {
  background: transparent;
  border: none;
  padding: 15px;
}

/* chat page css start  */
.chat_page {
  display: flex;
}

.all_tickets_left {
  overflow-y: auto;
  height: calc(100vh - 68px);
}

.all_tickets_left::-webkit-scrollbar {
  width: 4px;
  /* Adjust the width as needed */
}

.all_tickets_left::-webkit-scrollbar-thumb {
  background-color: #ced0d1;
  border-radius: 5px;
  /* Adjust the scroll thumb color as needed */
}

.all_tickets_left::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Adjust the scrollbar track color as needed */
}

.single_ticket.currently_open {
  background: var(--p-theme-green-bg);
}

.single_ticket_chat {
  position: relative;
}

.single_ticket .img_container {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.field_inner.i_and_s .inner_select label {
  position: absolute;
  top: -20px;
  left: 0;
}

.single_ticket .img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single_ticket .right h5 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  color: #303030;
  font-family: r_medium;
  padding-right: 50px !important;
}

.single_ticket .right h6 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 11px;
  color: var(--p-theme-grey);
  padding-right: 33px !important;
}

.single_ticket {
  display: flex;
  height: 72px;
  background: white;
  align-items: center;
  border-bottom: 1px solid var(--p-theme-grey);
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}

.single_ticket:hover {
  background: var(--p-theme-green-bg);
  transition: 0.3s;
}

.single_ticket .right {
  flex-grow: 1;
}

.single_ticket .right .time {
  color: #667781;
  font-size: 10px;
  position: absolute;
  top: 3px;
  right: 8px;
}

.single_ticket .right .unread_chat {
  width: 20px;
  height: 20px;
  background: var(--p-theme-purple);
  border-radius: 50%;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* line-height: 0px; */
  position: absolute;
  right: 8px;
  bottom: 5px;
}

.single_ticket .left {
  padding: 0px 12px;
}

.chat_list {
  opacity: 1;
  width: 25%;
  pointer-events: all;
  display: block;
}

.chat_list.hide {
  display: block;
}

.chat_window {
  width: 75%;
  padding: 0;
  display: block;
}

.chat_window.show {
  display: block;
}

@media only screen and (max-width: 1199px) {
  .chat_box_header .mobile_chat_back_button {
    display: flex;
    align-items: center;
  }

  .all_tickets_left .search-select {
    position: fixed !important;
    z-index: 9999;
    width: 100%;
    top: 64px;
  }


  .chat_list {
    width: 100% !important;
  }

  .chat_list.hide {
    display: none;
  }

  .chat_window {
    display: none;
    width: 100% !important;
  }

  .all_tickets_left {
    height: auto;
    margin-top: 40px;
  }

  .ticket_detail.chat_box {
    overflow: hidden;
    height: auto;
  }

}


/* react form tags css start  */
.react_tags {
  border: 1px solid #818181;
  padding: 19px 12px 6px 12px;
  border-radius: 7px;
  width: 100%;
  position: relative;
}

.react_tags label {
  position: absolute;
  margin: 0px;
  font-size: 14px;
  color: #000000;
  top: -14px;
  left: -1px;
  border: 1px solid #818181;
  border-radius: 5px 5px 5px 0px;
  padding: 3px 12px;
  background: white;
}

.react_tags .input-wrapper {
  background-color: transparent !important;
  width: 100%;
  border-radius: 0px !important;
  height: auto !important;
  padding: 0px !important;
  border: none !important;
}

.react_tags .search-select span {
  display: none;
}

.react_tags .css-gs9zxv-control,
.react_tags .css-egqb7g-control {
  min-height: auto;
  background: transparent;
  padding-left: 0px;
}

.new_input_and_select {
  border: 1px solid #818181;
  border-radius: 7px;
  /* overflow: hidden; */
  display: flex;
  gap: 10px;
  align-items: center;
}

.new_input_and_select .fl_form_field select,
.new_input_and_select .fl_form_field input {
  border: none;
  padding: 25px 12px 6px 12px;
  border-radius: 0px;
}

.new_input_and_select .fl_form_field.new_input {
  width: 75%;
}

.new_input_and_select .fl_form_field.new_select {
  width: 25%;
}

.new_input_and_select .fl_form_field.new_select select {
  height: 46px;
  background: #dfdfdf;
}



.add_property_fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 22px;
  grid-row-gap: 30px;
}

.fl_form_field.react_tags {
  background: white;
}

.n_select_bg select {
  background: #dfdfdf;
}

@media only screen and (max-width: 991px) {
  .add_property_fields {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 30px;
    margin-top: 15px;
  }

  .new_input_and_select .fl_form_field.new_select {
    width: 35%;
  }

  .new_input_and_select .fl_form_field.new_input {
    width: 65%;
  }
}

@media only screen and (min-width: 1200px) {
  .all_tickets_left input.input-wrapper {
    min-height: 59px;
  }
}

.fav_and_share {
  position: absolute;
  right: 0;
}

/* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */

/* input number increase decrese style */
.number-input-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.number-input-div h2 {
  font-size: 1.2rem;
  color: #444;
}

.number-input-div div {
  display: flex;
  align-items: center;
}

.number-input-div div input {
  width: 50px;
  text-align: center;
  pointer-events: none;
}

.number-input-div div input:disabled {
  background: #fff;
}

.number-input-div div div {
  position: relative;
  /* background: #fff; */
  background: var(--p-theme-green);
  width: 30px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.number-input-div div div.left-minus-button {
  border-radius: 8px 0 0 8px;
}

.number-input-div div div.right-plus-button {
  border-radius: 0 8px 8px 0;
}

/* .number-input-div div div.left-minus-button::before,
.number-input-div div div.right-plus-button::before {
  position: absolute;
  content: '';
  width: 1px;
  height: 25px;
  background: #ccc;
  border-radius: 20px;
}

.number-input-div div div.left-minus-button::before {
  right: 0;
}

.number-input-div div div.right-plus-button::before {
  left: 1px;
} */

.number-input-div div div span {
  color: #fff;
  /* color: #222; */
  font-size: 1.1rem;
  font-weight: 300;
}

.img-delete-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 999;
  width: 35px;
  height: 35px;
  background: #fff;
  border: 1px solid #666;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.img-delete-icon span {
  font-size: 1.35rem;
}