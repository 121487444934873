.logo_carousel {
    background: var(--theme-orange);
    padding: 30px 0px;
}

.lc_slide_single img {
    width: 100px !important;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;

}

.lc_right {
    position: relative;
}

.lc_right button.owl-prev span,
.lc_right button.owl-next span {
    font-size: 37px;
}

.lc_right button.owl-prev {
    width: 35px;
    height: 35px;
    top: -25px;
    left: -14px;
}

.lc_right button.owl-next {
    width: 35px;
    height: 35px;
    top: -25px;
    right: -14px;
}

.lc_slide_single {
    text-align: center;
}

.lc_slide_single h5 {
    font-size: 20px;
    margin-top: 5px !important;
    color: white;
    font-weight: 400;
}

.lc_slide_single h6 {
    font-size: 14px;
    color: white;
    font-weight: 400;
    text-transform: capitalize;
    margin-top: 5px !important;
}

.logo_carousel .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}

.logo_carousel .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
}

.logo_carousel .section_title_effect {
    font-size: 32px;
    -webkit-text-stroke-color: #ffffff;
    line-height: 32px;
}

.logo_carousel .section_title h3 {
    font-size: 18px;
    color: #ffffff;
    font-weight: 400;
    margin-top: 8px !important;
}

@media only screen and (max-width: 767px) {
    .logo_carousel {
        padding: 20px 0px;
        position: relative;
        z-index: 99;
    }


    .lc_slide_single img {
        width: auto;
        height: 28px;
        object-fit: cover;
    }

    .lc_slide_single h5 {
        font-size: 14px;
    }

    .logo_carousel .section_title h3 {
        font-size: 16px;
        text-align: center;
        max-width: 320px;
        width: 100%;
        margin: 8px auto 10px auto !important;
    }

    .logo_carousel .section_title_effect {
        font-size: 30px;
        line-height: 20px;
        text-align: center;
    }

    .lc_slide_single img {
        width: 65px !important;
        height: 65px;
    }

    .logo_carousel .react-multiple-carousel__arrow {
        min-width: 30px;
        min-height: 30px;
        z-index: 6;
    }

    .lc_right button.owl-prev {
        left: 5px;
    }

    .lc_right button.owl-next {
        right: 5px;
    }
    .lc_right {      
        margin-top: 14px;
    }
}