section.counter.sect_padding {
    padding: 0px;
    background: var(--theme-orange);
}
.count_bg_number {
    font-size: 114px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff4a;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
}
.cs_inner {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
    transform: translateY(-50%);
}
.cs_inner {
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    top: 53.5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    justify-content: center;
    transform: translateY(-50%);
}
.number_count {
    font-size: 46px;
    font-weight: 700;
    color: var(--white-color);
}
.count_which h6 {
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffffcc;
    position: relative;
    top: 5px;
}
.count_which h5 {
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 700;
    color: var(--white-color);
}


@media (max-width: 1199px) {
.count_bg_number {
    font-size: 100px;
}
.number_count {
    font-size: 35px;
}
.count_which h6 {
    font-size: 12px; 
}
.count_which h5 {
    font-size: 25px;
}

}
@media (max-width: 991px) {
    .counter.sect_padding .col-lg-3 {
        width: 50%;
    }
}
@media (max-width: 767px) {

.count_bg_number {
    font-size: 60px;   
}
.number_count {
    font-size: 25px;
}
.count_which h5 {
    font-size: 18px;
    margin-top: 4px !important;
}
.count_which h6 {
    font-size: 10px;
}
}