.progress-container {
    width: 300px;
    margin: 20px auto;
  }
  
  .linear-progress {
    height: 10px;
    background-color: #eee;
  }
  
  .circular-progress {
    margin-top: 10px;
  }
  