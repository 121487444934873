section.top_cities.sect_padding {
    padding-bottom: 0px;
}

.more-btn-info:hover {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 40px;
}

.more-btn-div {
    text-align: center;
    margin-top: 5rem;
}

.more-btn-info {
    width: 150px;
    color: white;
    border: 1px solid #20b2aa;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 40px 40px 40px 0px;
    font-weight: 500;
    transition: all 0.3s ease;
    background-color: #20b2aa;
}

section.top_cities .first_div {
    width: 28%;
}

section.top_cities .second_div {
    width: 72%;
}

.cities_single.title {
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    padding: 25px;
}

.cities_single.title:after {
    content: none !important;
}

.cities_single.title .section_title_effect {
    -webkit-text-stroke-color: #ffffff;
    z-index: 0;
}

section.top_cities .second_div .first_row {
    display: flex;
    flex-wrap: wrap;
}

.cities_single.sr_img_1 {
    width: 66.66%;
    height: 280px;
}

.cities_single.sr_img_2 {
    width: 33.33%;
    height: 280px;
}

section.top_cities .second_div .second_row {
    display: flex;
    flex-wrap: wrap;
}

.cities_single.first_row_img {
    width: 33.333%;
    height: 320px;
}

.cities_single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.top_cities {
    display: flex;
    flex-wrap: wrap;
}

section.top_cities .first_div .cities_single.down {
    height: 400px;
}

.cities_single {
    position: relative;
    transition: 0.4s;
}

.cities_single:after {
    content: '';
    background: black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.city_number {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    color: white;
    font-size: 100px;
    transition: 0.4s;
}

.cities_single:hover .city_number {
    color: rgb(0, 0, 0);
    opacity: 0.4;
    transition: 0.4s;

}

.cities_single h5 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    color: white;
    font-size: 30px;
    text-transform: capitalize;
    opacity: 0.9;
    transition: 0.4s;
}

.cities_single h6 {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 9;
    color: white;
    font-size: 16px;
    background: black;
    padding: 10px 15px !IMPORTANT;
    border-radius: 20px;
    text-transform: capitalize;
    transition: 0.4s;
}

.cities_single:hover h6 {
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    transition: 0.4s;
}

.cities_single:hover:after {
    opacity: 0;
    transition: 0.4s;
}

@media only screen and (max-width: 1199px) {
    .cities_single h5 {
        font-size: 22px;
        padding: 0px 5px;
    }

    .city_number {
        font-size: 65px;
    }

    section.top_cities.sect_padding {
        padding-top: 0px;
    }
}

@media only screen and (max-width: 991px) {
    section.top_cities {
        display: block;     
    }
    section.top_cities .first_div {
        width: 100%;
        display: flex;
    }
    .cities_single.title {
        width: 50%;
        padding: 15px;
    }
    section.top_cities .first_div .cities_single.down {
        width: 50%;
    }
    section.top_cities .second_div {
        width: 100%;
    }   
    .cities_single h6 {   
        padding: 5px 10px !IMPORTANT;    
        font-size: 12px;
    }
    .cities_single.sr_img_1, .cities_single.sr_img_2, .cities_single.first_row_img, 
    .cities_single.title, section.top_cities .first_div .cities_single.down  {       
        height: 220px;
    }

    
}
@media only screen and (max-width: 575px) {
    .cities_single h5 {
        font-size: 16px;
        padding: 0px 10px !important;
    }
.city_number {
    font-size: 45px;
}

}