.faq_section_single .section_title {
    text-align: center;
}
.faq_section_single .section_title h3 {
    padding-bottom: 20px !important;
    margin-bottom: 10px !important;
    display: inline-block;
    border-bottom: 1px solid #e9573354;
}
.how_use_propagent ol li{
    margin-top: 12px !important;
}
.how_use_propagent ol li ul li {
    margin-top: 4px !important;
    list-style-type: disc;
    margin-left: 10px !important;
}
.faq .faq_section_single {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); 
    padding: 35px;
    border-radius: 22px;
    max-width: 850px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: var(--white-color);
}
button.accordion-button.collapsed {
    font-weight: 500;
    font-size: 18px;
    color: #303030;
}
.faq .faq_section_single:last-child {
    margin-bottom: 0px;
}
.accordion {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}
.accordion .accordion-button:not(.collapsed) {
    color: var(--p-theme-purple);
    background: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
}
.accordion-body {
    color: grey;
    font-size: 14px;
}
.accordion button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}
.accordion-item {
    border: none;
}
button.accordion-button {
    border-bottom: 1px solid #e9573354;
}
.accordion-item .accordion-button:focus{
    border-color: #e9573354;
}
.accordion .accordion-button:not(.collapsed) {
    border-bottom: 1px solid var(--p-theme-purple);
}
button.accordion-button {
    border-bottom: 1px solid #e9573354;
    font-weight: 700;
    color: #333333;
    font-size: 20px;
}
.accordion-body p {
    margin-bottom: 10px !important;
}


@media (max-width: 767px) {
    .accordion .accordion-button:not(.collapsed) {       
        font-weight: 500;
        font-size: 14px;
    }
    button.accordion-button.collapsed {
        font-weight: 500;
        font-size: 14px;
    }
    
.faq_section_single .section_title h3 {
    padding-bottom: 12px !important;
    margin-bottom: 5px !important;
    font-size: 18px;
}
button.accordion-button {
    padding: 15px;
    font-size: 16px; 
}
.accordion-item .accordion-body {
    padding: 15px;
    font-size: 12px;
}
.faq .faq_section_single {
 
    padding: 10px;
}

}