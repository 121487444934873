.pd_single .pcs_inner {
    width: 100%;
    padding: 16px;
    display: flex;
    gap: 16px;
}
.pd_single .pcs_inner .pcs_image_area {   
    width: 40%;
}
.pd_single .pcs_inner .pcs_main_detail {
    width: 60%;
}
.small_images {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin-top: 8px;
    gap: 8px;
    overflow: hidden;
    border-radius: 0px 0px 12px 12px;
}

.small_image_single {
    width: 100%;   
    aspect-ratio: 3/2;
}
.small_image_single img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* .pd_single .bigimage_container {
    width: 100%;
    aspect-ratio: 1/0.5;
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
} */
.pd_single .pcs_inner .pcs_image_area img.bigimage{
    border-radius: 0px;
}
.pd_single  .property_card_single{
    margin-bottom: 16px;
}
.pd_single .more_detail_card_inner{
    padding: 16px;
}
.pd_single  .property_card_single{
    display: inherit;
}
.pd_single  .p_info {   
    column-gap: 60px;   
    row-gap: 16px;
    justify-content: inherit;
}
.pd_single .p_info_single {  
    width: 25%;
    padding: 0px 15px;
}
.pdms_single h4 span.currency {
    font-size: 16px;
    vertical-align: super;
}
.pd_single .pp_sidebar {
    margin-top: 0px;
}
.back_btn {
    display: flex;
    gap: 4px;
    color: black;
    background: white;
    padding: 10px 20px;
    border-radius: 30px;
}
.pdms_single h4 span.price {
    font-size: 16px;
    vertical-align: bottom;
    margin-left: 2px;
}
.pmd_section2 .pdms_single h4 {
    font-size: 20px;
    color: #292929;
}
.pmd_section2 .pdms_single h6{
    color: #999;
    font-size: 12px;
    font-weight: 400;
}
.pmd_section2 .pdms_single{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.pmd_section2.pmd_section3 .pdms_single h4 {
    display: flex;
    align-items: baseline;
    font-size: 18px;
}
.pd_single .pmd_top .property_name {
    font-size: 22px;
}
.pd_single .pmd_top .property_location {
    font-size: 18px;    
}
.pmd_section2.pmd_section3 .pdms_single h4 img {
    height: 22px;
    margin-right: 4px;
}
.pmd_section4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}
.property_full_address h3 {
    font-size: 14px;
    font-weight: 500;
    color: #7e7e7e;
}
.property_full_address h2.card_title{
    margin-bottom: 0px !important;
}
.property_connected_people .single_user .left {
    border-right: none;
    padding-right: 2px;
}
.userlist.property_owners {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.userlist.property_owners .single_user {  
    border: 1px solid;
    border-radius: 15px;
    border-color: #e7e7e7;   
    position: relative;
}
.userlist.property_owners .single_user .property_people_designation {
    position: absolute;
    top: -15px;
    right: 60px;
    font-size: 12px;  
    padding: 3px 10px;
}
.userlist.property_owners .single_user .property_people_designation:after {
    content: '';
    position: absolute;
    height: 50%;
    background: #f3f3f3;
    width: 10px;
    top: 2px;
    left: -5px;  
    transform: rotate(45deg);
}
.property_connected_people.userlist .single_user {
    padding: 0px;    
}
.pcp_single .contacts_single .icon span{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: var(--theme-green);
    font-size: 20px;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
}
.pcp_single .contacts_single .icon img {
    width: 40px;
    margin: 0 auto;
}
.pcp_single .contacts_single {
    text-align: center;
}
.pcp_single .contacts_single h6 {
    color: #666;
    font-weight: 400;
    font-size: 12px;
    margin-top: 4px !important;
}

.property_connected_people.userlist {
    display: block;  
}
.property_people_designation {
    background: #f3f3f3;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #303030;
    margin-bottom: 15px;
    margin-left: -15px;
    margin-right: -15px;
}
.property_connected_people.userlist .pcp_single {
    padding: 0px 15px 15px 15px;
    margin-top: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 15px;
    overflow: hidden;
    cursor: -webkit-grab;
}
