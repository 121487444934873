

.full-content.sidebar-open {
    padding-left: 250px;
    /* background: yellow; */
}

@media (max-width: 992px) {
    .full-content.sidebar-open {
        padding-left: 40px;
        /* background: yellow; */
    }
}

.full-content.no-sidebar {
    padding-left: 15px;
    /* background: green; */
}