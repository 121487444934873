section.hero img {
  width: 100%;
  /* aspect-ratio: 3/0.8; */
  aspect-ratio: 3/0.7;
  object-fit: cover;
}
section.hero:after {
  content: '';
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
}
.hero_component.hero_display_none {
  display: none;
}
.sn_inner {
  padding: 50px;
  max-width: 460px;
  width: 100%;
  position: relative;
margin-top: -110px;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
background: var(--white-color);
  border-radius: 22px;
}

.sn_inner h6 {
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  position: relative;
  display: inline-block;
}

.sn_inner h6:after {
  content: '';
  height: 5px;
  width: 100%;
  position: absolute;
  background: var(--p-theme-orange);
  bottom: 0;
  left: -50px;
}

.sn_inner h1 {
  font-size: 42px;
  color: var(--p-theme-orange);
}



@media only screen and (max-width: 767px) {
  section.hero img {
    aspect-ratio: inherit;
    height: 135px;
  }

  .sn_inner {
    padding: 15px;
    margin-top: -58px;
    width: fit-content;
  }

  .sn_inner h6 {
    margin-bottom: 5px !important;
    padding-bottom: 7px !important;
    font-size: 14px;
}

  .sn_inner h6:after {
    height: 2px;
    left: -15px;
    width: 50%;
  }

  .sn_inner h1 {
    font-size: 22px;
  }
}