.more-expand-div {
    /* position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    box-shadow: 0 -300px 1000px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 10px 10px 0 0;
    z-index: 999;
    overflow-y: auto; */
    /* position: relative;
    left: -14px;
    width: calc(100% - -28px); */
    padding: 10px 0px;
}

.more-div-profile-div {
    position: relative;
    background: var(--theme-green);
    border-radius: 8px;
    /* margin: 0 20px; */
    display: flex;
    align-items: center;
    padding: 20px 10px;
}

.more-div-profile-div .more-div-profile-div-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--cream-color);
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-div-profile-div .more-div-profile-div-img img {
    width: 100%;
    border-radius: 50%;
}

.more-div-profile-div .more-div-profile-div-img span {
    position: relative;
    top: 1px;
    font-size: 1.8rem;
    font-weight: 500;
}

.more-div-profile-div .more-div-profile-div-content {
    padding-left: 10px;
}

.more-div-profile-div .more-div-profile-div-content h1 {
    color: #fff;
    font-weight: 600;
    font-size: 1.5rem;
    padding-bottom: 5px;
    margin: 0;
}

.more-div-profile-div .more-div-profile-div-content h2 {
    font-size: 1rem;
    color: var(--cream-color);
    font-weight: 400;
    margin: 0;
}

.more-div-profile-div .more-div-profile-div-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #fff;
}

.more-div-big-icon-div {
    border-radius: 8px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    /* margin: 0 10px; */
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.more-div-big-icon-div .more-div-big-icon-div-inner {
    width: 33.3% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.more-div-big-icon-div .more-div-big-icon-div-inner div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--p-theme-purple-bg);
}

.more-div-big-icon-div .more-div-big-icon-div-inner div span {
    color: var(--p-theme-purple);
    font-size: 2rem;
    transition: 0.5s;
    font-variation-settings:
        'FILL' 0
}

.more-div-big-icon-div .more-div-big-icon-div-inner:hover div span {
    font-variation-settings:
        'FILL' 1
}

.more-div-big-icon-div .more-div-big-icon-div-inner h1 {
    font-size: 0.9rem;
    font-weight: bolder;
    padding-top: 10px;
    color: var(--p-theme-purple);
}

.more-div-card {
    /* border: 1px solid #ddd; */
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.more-div-card .more-div-card-heading {
    font-size: 1.4rem;
    font-weight: bolder;
    padding-bottom: 8px;
}

.more-div-card-inner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    background: #f4f4f4;
    /* background: var(--cream-color); */
    border-radius: 4px;
    padding-left: 10px;
    margin: 10px 0;
}

.more-div-card-inner .more-div-card-inner-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(84, 204, 203, 0.5);
    /* background: var(--peach-color); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-div-card-inner .more-div-card-inner-icon span {
    color: #222;
    /* color: var(--cream-color); */
    transition: 0.5s;
    font-size: 1.3rem;
    font-variation-settings:
        'FILL' 0
}

.more-div-card-inner:hover .more-div-card-inner-icon span {
    font-variation-settings:
        'FILL' 1
}

.more-div-card-inner .more-div-card-inner-content {
    width: calc(100% - 40px);
    padding: 0 20px;
}

.more-div-card-inner .more-div-card-inner-content h2 {
    font-size: 1rem;
    color: #444;
    font-weight: bolder;
    padding-bottom: 3px;
}

.more-div-card-inner .more-div-card-inner-content h3 {
    font-size: 0.7rem;
    color: #aaa;
    font-weight: bolder;
}

.more-div-card-inner .more-div-card-inner-arrow {
    position: absolute;
    top: 55%;
    right: 5px;
    transform: translateY(-50%);
}

.more-div-card-inner .more-div-card-inner-arrow span {
    font-size: 1.2rem;
    color: var(--theme-green);
    font-weight: bold;
}