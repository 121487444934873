.property-list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 20px;
}

.property-list a {
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
}

.property-list h4 {
    font-size: 0.9em;
    color: var(--heading-color);
}

.property-list p {
    color: var(--text-color);
    font-size: 0.9em;
}

.property-list .assigned-to {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.property-list ul {
    margin: 10px 0;
    display: flex;
}

.property-list li {
    margin-right: 10px;
}

.property-list .avatar {
    width: 30px;
    height: 30px;
}

.tenant-profile-property-detail {
    position: relative;
    padding: 10px 15px;
    /* border-top: 1px solid var(--peach-color); */
    /* border-bottom: 1px solid var(--peach-color); */
}

.tenant-profile-property-detail::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0.5px;
    width: calc(100% - 30px);
    background: var(--peach-color);
}

.tenant-profile-property-detail-inner-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tenant-profile-property-detail-inner-div div:nth-child(2) {
    text-align: right;
}

.tenant-profile-property-detail-inner-div h1 {
    font-size: 0.7rem;
    color: var(--lightgrey-color);
    margin: 0;
}

.tenant-profile-property-detail-inner-div h2 {
    font-size: 1rem;
    color: var(--darkgrey-color);
    margin: 0;
}

.secondary-details-display {
    display: flex;
    width: 100%;
}

.secondary-details-display .secondary-details-inside-display {
    width: 100%;
    border-right: 2px solid var(--lightgrey-color);

}

.secondary-details-inside-display .secondary-details-inside-display-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondary-details-hr {
    display: none;
}

@media (max-width: 900px) {
    .secondary-details-display {
        flex-direction: column;
    }

    .secondary-details-display .secondary-details-inside-display {
        border: none;
    }

    .secondary-details-hr {
        display: block;
    }
}

.secondary-details-inside-display-btn-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 0 15px 15px 15px; */
}

.secondary-details-inside-display-btn-div div {
    width: 100%;
    background: var(--peach-color);
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-top: none;
    cursor: pointer;
}

.secondary-details-inside-display-btn-div div h1 {
    font-size: 0.8rem;
    font-weight: bolder;
    color: var(--text-color);
}

.property-contact-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    flex-direction: column;
    height: 100%;
    /* width: 350px; */
}

/* @media (max-width: 850px) {
  .property-contact-div {
    width: 100%;
  }
} */

.property-contact-div div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.property-contact-div div span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    color: var(--red-color);
    font-size: 1.2rem;
    background: #fff;
}

.property-contact-div div h1 {
    font-size: 0.7rem;
    margin: 0;
    padding-top: 5px;
    color: var(--darkgrey-color);
}

.property-contact-div div img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
}

@media (max-width: 850px) {
    .property-media-icons-horizontal {
        flex-direction: row;
    }
}


