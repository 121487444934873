.mm_inner {
    border-radius: 22px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: var(--white-color);
}

a.mm_single {
    display: block;
    padding: 15px 50px;
    background:#f9f5ff80;
    position: relative;
    margin-top: 15px;
    color: var(--p-theme-purple);
}

.mm_inner a.mm_single:first-child {
    margin-top: 0px;
}

a.mm_single span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

a.mm_single span.mms_icon {
    left: 15px;
}

a.mm_single span.mms_ra {
    right: 15px;
}
.mm_inner h6.title {
    padding: 15px !important;
    padding-bottom: 0px !important;
    font-size: 20px;
}
.more-menus_inner {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 22px;
    grid-row-gap: 22px;
}
.more-menus .more-div-big-icon-div { 
    border-radius: 22px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
a.mm_single h6 {
    font-size: 12px;
    font-weight: 400;
    margin-top: 2px !IMPORTANT;
    text-transform: lowercase;
    opacity: 0.6;
}


.pgmoremenu .more-div-big-icon-div .more-div-big-icon-div-inner { 
    gap: 7px;
}
.pgmoremenu .more-div-big-icon-div .more-div-big-icon-div-inner h1 {
    font-size: 16px;
    font-weight: 500;
 
}
a.mm_single h5 {
    font-size: 16px;
}
@media screen and (max-width: 767px) {
.more-menus_inner {
    grid-template-columns: repeat(1,1fr);
    grid-row-gap: 15px;
}
.pgmoremenu .more-div-big-icon-div .more-div-big-icon-div-inner h1 {
    font-size: 14px;  
}
a.mm_single h5 {
    font-size: 14px;
}
.mm_inner h6.title {
    font-size: 18px;
}
}
