.profile-card-div {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: var(--white-color);
    border-radius: 22px;

}

.profile-card-div .content {
    padding: 10px;
}

.user-name {
    position: relative;
    display: flex;
    align-items: center;
}

.user-logo-parent-div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-logo-parent-div .user-logo {
    width: 85px;
    height: 85px;
    background: rgba(84, 204, 203);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-logo-parent-div .user-logo .avatar {
    width: 80px;
    height: 80px;
}

@media (max-width: 850px) {
    /* .user-logo-parent-div {
    display: block;
  } */

    .user-logo-parent-div .user-logo {
        width: 85px;
        height: 85px;
    }

    .user-logo-parent-div .user-logo .avatar {
        width: 80px !important;
        height: 80px !important;
    }
}

.user-logo-parent-div .user-logo h4 {
    position: relative;
    top: 5px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 500;
}

.user-logo-parent-div .user-logo img {
    width: 100%;
    border-radius: 50%;
}

.user-name .details,
.property-status-property-card .details {
    padding: 10px 15px;
}

.property-status-property-card .details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px;
}

@media (max-width: 850px) {
    .property-status-property-card .details {
        text-align: center;
        padding: 20px 10px 0 10px;
    }
}

.user-name .details .profile-change-name {
    font-size: 1.3rem;
    color: #666;
    font-weight: 600;
    border: none;
    padding: 0 0 0 8px;
    background: #eee;
    /* background: var(--yellow-color); */
    border: 1px dashed var(--lightgrey-color);
    width: 90%;
    margin-bottom: 8px;
}

.user-name .details p {
    margin: 0;
    color: #aaa;
    font-size: 0.9rem;
    font-weight: 500;
}

.user-name .details .profile-change-phone {
    margin: 0;
    color: #aaa;
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    padding: 0 0 0 8px;
    width: 120px;
    background: #eee;
    border: 1px dashed var(--lightgrey-color);
}

.user-name .details .profile-change-name:read-only,
.user-name .details .profile-change-phone:read-only {
    background: none;
    border: 1px dashed transparent;
}

/* change number pop up */

.pop-up-change-number-div {
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: 1s;
}

.pop-up-change-number-div.open {
    opacity: 1;
    pointer-events: all;
}

.pop-up-change-number-div .direct-div {
    position: relative;
    background: var(--white-color);
    padding: 20px;
    border-radius: 10px;
    max-width: 380px;
    width: 100%;
    text-align: center;
}

/* change number pop up */

.change-number-input .react-tel-input input.form-control {
    border: 1px solid #d7d7d7;
    border-radius: 0px;
    padding: 25px 12px 6px 12px;
    border-radius: 7px;
}

.change-number-input .react-tel-input .special-label {
    position: absolute;
    margin: 0px;
    font-size: 14px;
    color: #909090;
    top: 5px;
    left: 12px;
}

.edit-number {
    position: absolute;
    left: 65%;
    transform: translate(0, -27px);
}

.edit-number span {
    font-size: 1rem;
    color: var(--p-theme-purple);
    width: 20px;
    height: 20px;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.sroll-outter-div {
    width: 100%;
    overflow: hidden;
}

.sroll-outter-div .sroll-inner-div {
    width: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sroll-outter-div .sroll-inner-div .scroll-div {
    width: 100%;
    padding: 20px 10px 10px 10px;
    transform: translateX(0%);
    transition: 1s;
}

.change-number-button-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
}

.change-number-button-div button {
    border-radius: 8px;
    height: 35px;
    width: 100px;
}

.pop-up-change-number-div .direct-div .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: var(--p-theme-purple);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
}

.user-name .edit {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    cursor: pointer;
}

.user-name .edit span {
    color: #666;
    font-size: 1.3rem;
}

.user-name .edit span.done {
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    background: var(--theme-blue);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-profile-div {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: var(--yellow-color);
    border-radius: 0 0 4px 4px;
}

.edit-profile-div small {
    margin: 0;
    width: 70%;
    font-size: 0.8rem;
    color: #666;
    font-weight: 500;

}

.edit-profile-div div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}

.address-div {
    display: grid;
    grid-template-columns: 10% 90%;
    padding: 15px;
    background: #f9f5ff80;
}

.address-div .icon {
    position: relative;
    top: 3px;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: var(--p-theme-purple-bg);
    color: var(--p-theme-purple);
}

.address-div .icon span {
    font-size: 1.2rem;
}

.address-div .address-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 0px 20px;
}

.address-div .address-text h5 {
    position: relative;
    top: -5px;
    font-size: 16px;
    color: var(--p-theme-purple);
    margin-bottom: 0;
}

.address-div .address-text small {
    position: relative;
    top: -4px;
    margin-top: 0;
    font-size: 0.7rem;
    color: #aaa;
}

.address-div .address-text div {
    text-align: right;
    position: relative;
    top: -3px;
}

.address-div .address-text div span {
    color: var(--p-theme-purple);
}

.dashboardCardBoxoffer {
    /* padding: 10px; */
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    height: 9rem;
    width: 100%;
    transition: 1s;
    overflow: hidden;
}

@media (max-width: 850px) {
    .dashboardCardBoxoffer {
        height: auto;
    }
}

.profile-upload-input {
    width: 0;
    height: 0;
    opacity: 0;
}

.profile-upload-label {
    position: absolute;
    bottom: -20%;
    right: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--theme-blue);
    color: #fff;
}

.profile-upload-label span {
    font-size: 16px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}




/* sanskar profile css start  */
.profile_card .sn_inner {
    padding: 25px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.profile_card .user_img {
    border-radius: 18px;
    width: 142px;
    height: 142px;
    margin: -100px auto 0px auto;
}

.profile_card .user_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 22px;
    object-position: top;
}

.profile_card .profile-upload-label {
    bottom: -30px;
    right: -6px;
    background: var(--p-theme-purple);

}

h4.user_name {
    margin: 20px 0px 2px 0px !important;
}


h4.user_name input {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    border-bottom: none;
    padding: 0px;
    border: 1px solid var(--p-theme-purple);
}

.profile_card .sn_inner h5 {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: var(--p-theme-grey);
}

.profile_card .sn_inner h5 input {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
}

.profile_card .sn_inner input:read-only {
    padding: 5px;
    border: 1px dashed transparent;
    color: var(--p-theme-grey);
    background: transparent;
}

.profile_card .sn_inner input.profile-change-name:read-only {
    border: 1px solid var(--lightgrey-color);
}

.profile_card .sn_inner input {
    padding: 5px;
    color: #666;
    background: #eee;
}

.profile_card .sn_inner h5 input {
    max-width: fit-content;
}

.profile_card .sn_inner .edit {
    position: absolute;
    background: var(--p-theme-purple);
    padding: 8px;
    border-radius: 50%;
    top: 50px;
    right: 35px;
    transform: translateY(45px) translateX(-50px);
}

.profile_card .sn_inner .edit.edit_done {
    background: var(--p-theme-green);
}

.profile_card .sn_inner .edit span {
    color: var(--white-color);
    font-size: 20px;
}

.visit_dashboard {
    padding: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 22px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: var(--white-color);
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    width: 100%;
    color: var(--p-theme-grey);
    font-size: 18px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .visit_dashboard {
        padding: 10px;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        margin: auto;
        border-radius: 20px;
        font-size: 14px;
    }

    .profile-card-div,
    .more-menus .more-div-big-icon-div,
    .mm_inner {
        border-radius: 20px !important;
    }

    .profile_card .sn_inner .edit {
        padding: 5px;
        top: 8px;
        right: 15px;
    }

    .address-div .address-text h5 {

        font-size: 14px;

    }

    .profile_card .user_img {
        border-radius: 15px;
        width: 125px;
        height: 125px;
    }

    .profile_card .user_img img {
        border-radius: 15px;
        border: 3px solid var(--theme-blue);
    }

    .profile_card .sn_inner {
        padding: 15px;
        width: 100%;
        border-radius: 20px;
        margin-top: -15px;
    }

    h4.user_name {
        margin: 10px 0px 2px 0px !important;
    }

    h4.user_name input {
        font-size: 20px;
    }

    .profile_card .sn_inner h5 input,
    .profile_card .sn_inner h5 {
        font-size: 16px;
    }
}