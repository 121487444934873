.bigimage_container .image-gallery-content .image-gallery-slide .image-gallery-image, .bigimage_container .image-gallery-thumbnail .image-gallery-thumbnail-image {  
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
}
.bigimage_container .image-gallery-thumbnail.active, .bigimage_container .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid #161616;
}
.bigimage_container .image-gallery-thumbnail:hover {
    outline: none;
    border: 2px solid #161616;
}
.bigimage_container .image-gallery-thumbnails {   
   padding: 0px;
    padding-top: 6px;
    border-radius: 0px 0px 16px 16px;
}
.bigimage_container .image-gallery-swipe {
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
}
.bigimage_container .image-gallery-left-nav .image-gallery-svg, .bigimage_container .image-gallery-right-nav .image-gallery-svg {
    height: 40px;
    width: 40px;
    background: #00000057;
    border-radius: 50%;  
}
.bigimage_container .image-gallery-icon {
    filter: none;
}
.bigimage_container .image-gallery-fullscreen-button, .bigimage_container .image-gallery-play-button { 
    padding: 8px;
}
.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
    height: 40px;
    width: 40px;
    background: #00000057;
    padding: 7px;
    border-radius: 50%;
}