.blog_sect .card-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 40px;
}

.blog_sect .card-image img {
  width: 100%;
  background-size: cover;
  aspect-ratio: 3/2;
}
.blog_source img {
  height: 36px;
  width: auto !important;
}
.blog_sect .card-body {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 22px;
  position: relative;
}

.blog_sect .card-body:after {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  background: #e957334f;
  border-radius: 50%;
  top: -12px;
  left: -62px;
}

.blog_sect .card-body:before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  background: #e957334f;
  border-radius: 50%;
  right: -45px;
  bottom: -62px;
}

.blog_sect .card-badge {
  text-transform: uppercase;
  background-color: var(--theme-orange);
  color: #fff;
  padding: 4px 10px;
  border-radius: 70px;
  margin: 0;
  font-size: 12px;
}

.blog_sect .card-body h3 {
  font-size: 16px;
  margin: 8px 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #303030;
}

.blog_sect .card-body p {
  font-size: 14px;
  margin-bottom: 16px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: grey;
}

.blog_sect .card-author {
  display: flex;
  align-items: center;
  gap: 12px;
}

.blog_sect .card-author p {
  margin: 0 16px !important;
  font-size: 12px;
}

.blog_sect .card-author p:last-child {
  color: #888;
}

.blog_sect .card-author img {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  margin-top: auto;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .blog_source img {
    height: 25px;
  }
}