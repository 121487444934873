/* footer section css start  */
footer.footer-section section.loc_em_ph {
    position: absolute;
    top: -178px;
    width: 100%;
    left: 0;
}
footer.footer-section {
    margin-top: 145px;
    position: relative;
    padding-top: 190px;
    padding-bottom: 80px;
}
footer.footer-section.margin-padding {
    padding-top: 80px;
    margin-top: 0px;
}
.footer_site_logo p {
    color: var(--white-color);
    font-size: 14px;
    margin-top: 20px !important;
    line-height: 18px;
}
.footer_site_logo img {
    width: 180px;
}
.footer_single_title {
    font-size: 24px;
    font-weight: 500;
    color: var(--theme-orange);
    margin-bottom: 20px;
    padding-bottom: 10px;
    position: relative;
}
.footer_single_title::after{
    content: '';
    width: 28%;
    height: 4px;    
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--theme-orange);
    border-radius: 2px;
}
section.main_footer .col-md-3 {
    border-right: 1px solid #ffffff36;
}
section.main_footer .col-md-3:last-child {
    border-right: none;
}
.footer_link {
    display: grid;
    padding-left: 25px;
}
.footer_link a{
    color: var(--white-color);
    font-weight: 500;
}
.footer_link a.active_f_link {
    color: var(--theme-green);     
}
.footer_link a:hover{
    color: var(--theme-green);  
}
a.fsi_single {
    width: 50px !important;
    height: 50px !important;
    padding: 8px;
    background: var(--white-color);
    display: block;
    border-radius: 10px;
}
a.fsi_single img{
    width: 100%;
}
.footer_social_icon {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
}

/* footer section css end  */


/* copyright area css start  */

.copyright-area {
    bottom: 0;
    background-color: var(--p-theme-orange);
    position: absolute;
    width: 100%;
}

.copyright-area .copyright-item {
    padding-top: 20px;
    padding-bottom: 20px;
}

.copyright-area .copyright-item p {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
}

img.footer_icon {
    width: 34px;
}

.footer-contact li {
    position: relative;
}
.single-cta span.material-symbols-outlined {
  font-size: 50px;
  color: var(--theme-orange);
  font-weight: 600;
}
@media only screen and (max-width:1199px) {
    .copyright-area {      
       
        padding-bottom: 15px;
    }
}
@media only screen and (max-width:991px) {
    .footer_single_title {  
        margin-top: 35px;
    }
}
@media only screen and (max-width:767px) {
footer.footer-section {    
    padding-top: 285px; 
    padding-bottom: 35px;  
}
footer.footer-section.margin-padding {
    padding-top: 35px;
 
}
.footer_single_title {
    font-size: 20px;   
    margin-bottom: 8px;
    padding-bottom: 8px;
    margin-top: 10px;   
}
.footer_single_title::after { 
    width: 8%;
    height: 2px;
}
.footer_site_logo p {
    max-width: 320px;
    width: 100%;
}
.footer_link a { 
    font-size: 14px;
}
}


/* copyright area css end*/